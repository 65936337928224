/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  getCommunicationsSelector,
  getCompaniesSelector,
  getDraftTodoList,
  getUserSubscriptionTypeSelector,
  getSearchContactResults,
} from "../store/selectors"
import { getCompanyResults } from "../store/selectors/companies/get-companies-selector"
import { handleCompanyQueryChange } from "../store/actions/contacts/search-company"
import { handleContactQueryChange } from "../store/actions/contacts/get-contacts-list"
import { getCommunications } from "../store/actions/communication/get-communications"
import { getJobTodosSelector } from "../store/selectors/jobs/get_specific_job"
import { useUploadFile } from "./useUploadFile"
import { debounce, isEmpty, isEqual } from "lodash"
import { addLead } from "../store/actions/jobs/add-job"
import { setSpecificLead, updateJobTH } from "../store/actions/jobs/update-job"
import { resetDrafts } from "../store/actions/communication/create-communication"
import moment from "moment"
import { DATE_FORMAT } from "../common/config"
import { createTodoTH } from "../store/actions/todos/create_todo"
import {
  updateDraftTodoList,
  resetDraftTodoList,
} from "../store/actions/todos/draft_todos"
import { deleteTodo } from "../store/actions/todos/delete"
import {
  FeaturesTypes,
  JOB_APPLIED,
  JOB_DISCARDED,
  JOB_NOT_APPLIED,
  CRM_MODE,
} from "../common/constants"
import { downloadFile } from "../store/actions/file_manager/download-file"
import { getDraftTagsJobs } from "../store/selectors/jobs/get_jobs_selector"
import { setDraftTagsJob } from "../store/actions/jobs/add-job"
import { LinkItem } from "../components/notes/link_item"
import { deleteJobTH } from "../store/actions/jobs/delete-job"
import { Badge } from "antd"
import { useLeads } from "./useLeads"
import { useTags } from "./useTags"
import {
  removeExtension,
  getNameLead,
  focusOnLastModal,
} from "../common/helpers"
import { getJobTodos } from "../store/actions/jobs/get-jobs"
import { useActivityItem } from "./useActivityItem"
import { getCompany } from "../store/actions/companies/get-companies"
import {
  getContact,
  setContactToList,
} from "../store/actions/contacts/get-contacts-list"
import { updateTodoTh } from "../store/actions/todos/update-todo"
import { useAction } from "./useAction"
import { getTodos } from "../store/actions"
import { URLS } from "../common/urls"
import { updateModalProps } from "../store/actions/ui/update_ui"
import { getIsUILoading } from "../store/selectors/ui/get-ui"
import {
  getContactsSelector,
  getContactSearchLoading,
} from "../store/selectors/contacts/get-contacts-selector"
import { searchContactSuccess } from "../store/actions/contacts/get-contacts-list"
import { searchCompanySuccess } from "../store/actions/contacts/search-company"

export const useAddLead = (isAdd, isEdit, onClose, setDefaultActiveKey) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isEditTodo, setIsEditTodo] = useState(false)
  const [companyIsEdit, setCompanyIsEdit] = useState(false)
  const [isContactEdit, setContactEdit] = useState(false)
  const [pickerIsOpen, setPickerIsOpen] = useState(false)
  const datePickerRef = useRef()
  const [urlIsEdit, setUrlIsEdit] = useState(false)
  const { handleClick } = useActivityItem()
  const leadModalRef = useRef()
  const linkInputEl = useRef(null)
  const [fileTitle, setFileTitle] = useState("")
  const [openResume, setOpenResume] = useState(false)
  const communications = useSelector(getCommunicationsSelector)
  const draftTodos = useSelector(getDraftTodoList)

  const { specificLead, specificLeadOwnTodo } = useLeads()
  const [ownTodoDesc, setOwnTodoDesc] = useState(
    specificLeadOwnTodo?.description
  )
  const [addTagStep, setAddTagStep] = useState(0)
  const inputRef = React.useRef()
  const companyNameRef = React.useRef()
  const contactNameRef = React.useRef()
  const dispatch = useDispatch()
  useTags()
  const [canShow, setCanShow] = useState(false)
  const companies = useSelector(getCompaniesSelector)
  const companiesResults = useSelector(getCompanyResults)
  const contacts = useSelector(getContactsSelector)
  const contactsResults = useSelector(getSearchContactResults)
  const isSearchCompanyLoading = useSelector(getIsUILoading)
  const isSearchContactLoading = useSelector(getContactSearchLoading)
  const [confirmModal, setConfirmModal] = useState(false)
  const [jobFollowed] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const todos = useSelector(getJobTodosSelector)
  const [popOverVisible, handleVisibleChange] = useState(false)
  const currentTags = useSelector(getDraftTagsJobs)
  const subType = useSelector(getUserSubscriptionTypeSelector)
  const { onUpload } = useUploadFile()
  const location = useLocation()
  const createJobAction = useAction(addLead)
  const updateJobAction = useAction(updateJobTH)
  const deleteJobAction = useAction(deleteJobTH)
  const deleteTodoAction = useAction(deleteTodo)
  const createTodoAction = useAction(createTodoTH)
  const updateTodoAction = useAction(updateTodoTh)
  const getTodosAction = useAction(getTodos)
  const getCommunicationsAction = useAction(getCommunications)
  const getJobTodosAction = useAction(getJobTodos)
  const setSearchContactList = useAction(searchContactSuccess)
  const setSearchCompanyList = useAction(searchCompanySuccess)

  const [data, setData] = useState({
    ...specificLead,
  })

  const [onHoverDate, setHoverDate] = useState(false)
  const mouseHover = () => setHoverDate((prev) => !prev)

  const getTodosIfNeed = useCallback(async () => {
    if (location.pathname === URLS.HOME) {
      await getTodosAction()
    }
  }, [])
  const isCompanyExist = useCallback(() => {
    return companies?.find((item) => {
      return item?.name?.trim() === data?.company?.name?.trim()
    })
  }, [companies, data?.company?.name])

  const isContactExist = useCallback(() => {
    return contacts?.find((item) => {
      return item?.first_name?.trim() === data?.contact?.first_name?.trim()
    })
  }, [contacts, data?.contact?.first_name])

  const isClickOnCompany = useCallback(
    () => Boolean(data.title && isCompanyExist()),
    [data.title, isCompanyExist]
  )

  const isClickOnContact = useCallback(() => {
    return Boolean(data.title && isContactExist())
  }, [data.title, isContactExist])
  const isClickable = isClickOnCompany()
  const isClickableContact = isClickOnContact()

  const handlePrepareData = useCallback(() => {
    const inputData = Object.assign({}, data)

    if (!inputData?.contact?.first_name) {
      delete inputData.contact
      inputData.contact_id = null
    }

    if (inputData?.contact_id) {
      delete inputData.contact
    }
    if (!inputData?.contact_id && inputData?.contact?.id) {
      inputData.contact_id = inputData.contact.id
      delete inputData.contact
    }

    if (!inputData?.company?.name) {
      delete inputData.company
      inputData.company_id = null
    }

    if (inputData?.company_id) {
      delete inputData.company
    }
    if (!inputData?.company_id && inputData?.company?.id) {
      inputData.company_id = inputData.company.id
      delete inputData.company
    }

    inputData?.company?.tags && delete inputData.company.tags
    inputData?.contact?.tags && delete inputData.contact.tags
    inputData?.tags && delete inputData.tags
    const lead = { ...specificLead }
    lead?.company?.tags && delete lead.company.tags
    lead?.contact?.tags && delete lead.contact.tags
    lead?.tags && delete lead.tags

    for (const i in inputData) {
      if (!inputData.hasOwnProperty(i) || !lead.hasOwnProperty(i)) {
        continue
      }
      if (isEqual(inputData[i], lead[i])) {
        delete inputData[i]
      }
    }
    return inputData
  }, [companies, data])
  const handleAddLead = () => {
    if (companyIsEmpty() && contactIsEmpty()) {
      setDefaultActiveKey("1")
      setCanShow(true)
      setTimeout(() => companyNameRef?.current?.focus(), 100)
      return
    }

    const inputData = handlePrepareData()
    if (isAdd) {
      inputData.tags = currentTags || []
      delete inputData.resume
      dispatch(addLead(inputData))
    } else if (isEdit) {
      // TODO ID
      delete inputData.tags
      delete inputData.resume
      delete inputData.resume_id
      dispatch(updateJobTH({ ...inputData, id: specificLead.id }, true))
    }
    dispatch(resetDrafts())
    setData({
      description: "",
      url: "",
      url_icon: "",
      tags: [],
      status_id: 1,
      title: "",
      location: "",
      company: {},
    })
    onClose()
  }

  const handleCompanySearch = (query) => {
    dispatch(handleCompanyQueryChange(query))
  }
  const debouncedAction = useCallback(debounce(handleCompanySearch, 700), [])

  const handleContactSearch = (query) => {
    dispatch(handleContactQueryChange(query))
  }

  const debouncedContactSearch = useCallback(
    debounce(handleContactSearch, 700),
    []
  )
  const renderDueDate = useCallback(() => {
    const renderContent = (title, date) => {
      return <>{date}</>
    }

    if (isEdit && !isEmpty(specificLead.todos)) {
      return specificLead.todos[0].done_dt
        ? renderContent(
            "Done",
            moment(specificLead.todos[0].done_dt).format(DATE_FORMAT)
          )
        : renderContent(
            "Due",
            moment(specificLead.todos[0].due_dt).format(DATE_FORMAT)
          )
    } else {
      return renderContent("Due", moment().add(1, "week").format(DATE_FORMAT))
    }
  }, [isEdit, specificLead?.todos])

  const checkFollow = useCallback(() => {
    return isEdit ? !isEmpty(specificLead.todos) : jobFollowed
  }, [isEdit, jobFollowed, specificLead.todos])
  const toggleCommunicationModal = async () => {
    if (companyIsEmpty() && contactIsEmpty()) {
      setDefaultActiveKey("1")
      setCanShow(true)
      setTimeout(() => companyNameRef?.current?.focus(), 100)
      return
    } else {
      if (isAdd) await callbackAutoCreateLead()
      handleClick(
        { id: "newConvo" },
        FeaturesTypes.MINI_COMMUNICATION,
        {},
        { isContact: false }
      )
    }
  }
  const handleJobApply = async () => {
    if (companyIsEmpty() && contactIsEmpty()) {
      setDefaultActiveKey("1")
      setCanShow(true)
      setTimeout(() => companyNameRef?.current?.focus(), 100)
      return
    }
    const status_id =
      data.status_id === JOB_APPLIED ? JOB_NOT_APPLIED : JOB_APPLIED

    const apply_dt = status_id === JOB_APPLIED ? moment().toISOString() : null

    if (!specificLead?.id) {
      setIsLoading(true)
      const inputData = handlePrepareData()
      inputData.tags = currentTags || []
      delete inputData.resume
      const lead = await createJobAction(inputData)
      if (apply_dt && isEmpty(lead.todos)) {
        const due_dt = moment().add(1, "week").toISOString()
        const d = {
          job: lead.id,
          description: "follow up",
          status_id: 1,
          due_dt,
          lead_follow_up: true,
        }
        await createTodoAction(d, null, true)
      }
      const newLead = await updateJobAction({
        status_id,
        id: lead.id,
        apply_dt,
      })
      setData({ ...data, status_id, apply_dt })

      await getTodosIfNeed()
      dispatch(setSpecificLead(newLead))
      if (newLead?.contact?.id) {
        dispatch(setContactToList(newLead.contact))
      }
      dispatch(resetDrafts())
      dispatch(setDraftTagsJob([]))

      if (draftTodos?.length > 0) {
        await getJobTodosAction(`?job_id=${newLead?.id}`)
      }
      if (communications?.drafts?.length > 0) {
        await getCommunicationsAction(`?job_id=${newLead?.id}`)
      }
      dispatch(
        updateModalProps({
          isAdd: false,
          isEdit: true,
          status: "edit",
          is_communication_edit: true,
          type: FeaturesTypes.LEAD,
        })
      )
      setIsLoading(false)
      setIsChanged(false)
      return
    }

    if (!isEmpty(specificLead)) {
      if (apply_dt && isEmpty(specificLead.todos)) {
        const due_dt = moment().add(1, "week").toISOString()
        const data = {
          job: specificLead.id,
          description: "follow up",
          status_id: 1,
          due_dt,
          lead_follow_up: true,
        }
        await createTodoAction(data, null, true)
      }
      if (!apply_dt && !isEmpty(specificLead.todos)) {
        await deleteTodoAction(specificLead.todos[0]?.id)
      }

      await updateJobAction({ status_id, id: specificLead.id, apply_dt })
      setData({ ...data, status_id, apply_dt })
      await getTodosIfNeed()
    }
  }
  const handleEditLead = (e, name) => {
    const key = name
      ? name
      : e.target.dataset?.name || e.target.props["data-name"]
    if (!key) {
      return
    }
    if (key === "contact") {
      const isExist = contactsResults?.find(
        (item) => item?.first_name?.trim() === e?.trim()
      )
      if (isExist) {
        setData({ ...data, contact: isExist, contact_id: isExist.id })
        dispatch(setSpecificLead({ ...specificLead, contact: isExist }))
        if (!isContactExist()) {
          dispatch(setContactToList(isExist))
        }
        return
      }
      if (typeof e === "string") {
        setData({
          ...data,
          contact: {
            first_name: e,
          },
        })
        return
      }
      setData({ ...data, contact: e })
    }
    if (key === "company") {
      const isExist = companiesResults?.find(
        (item) => item?.name?.trim() === e?.trim()
      )
      if (isExist) {
        setData({ ...data, company: isExist, company_id: isExist.id })
        dispatch(setSpecificLead({ ...specificLead, company: isExist }))
        return
      }
      if (typeof e === "string") {
        setData({
          ...data,
          company: {
            name: e,
          },
        })
        return
      }
      setData({ ...data, company: e })
    }
    if (!confirmModal) {
      !isChanged && setIsChanged(true)
      let updatedValue = { ...data }
      updatedValue[key] = name ? e : e.target.value
      setData(updatedValue)
    }
  }

  const handleCloseOrConfirm = (e) => {
    if (popOverVisible) {
      handleVisibleChange(false)
      return
    }
    if (e.keyCode === 27 && addTagStep > 0) {
      e.stopPropagation()
      e.preventDefault()
      setAddTagStep((prev) => prev - 1)
      return
    }
    if (isChanged) {
      setConfirmModal(true)
    } else {
      onClose()
    }
  }

  const handleAutoCreateLead = async (d) => {
    setIsLoading(true)
    const inputData = handlePrepareData()
    inputData.tags = currentTags || []
    inputData.resume_id = d.id
    delete inputData.resume
    const lead = await createJobAction(inputData)
    dispatch(setSpecificLead(lead))
    if (lead?.contact?.id) {
      dispatch(setContactToList(lead.contact))
    }
    setData(lead)
    dispatch(resetDrafts())
    dispatch(setDraftTagsJob([]))

    if (draftTodos?.length > 0) {
      await getJobTodosAction(`?job_id=${lead?.id}`)
    }
    if (communications?.drafts?.length > 0) {
      await getCommunicationsAction(`?job_id=${lead?.id}`)
    }
    dispatch(
      updateModalProps({
        isAdd: false,
        isEdit: true,
        status: "edit",
        is_communication_edit: true,
        type: FeaturesTypes.LEAD,
      })
    )
    setIsChanged(false)
    setIsLoading(false)
  }

  const callbackAutoCreateLead = useCallback(async () => {
    if (!specificLead.id) {
      setIsLoading(true)
      const inputData = handlePrepareData()
      inputData.tags = currentTags || []
      const lead = await createJobAction(inputData)
      dispatch(setSpecificLead(lead))
      if (lead?.contact?.id) {
        dispatch(setContactToList(lead.contact))
      }

      setData(lead)

      await getJobTodosAction(`?job_id=${lead?.id}`)

      await getCommunicationsAction(`?job_id=${lead?.id}`)

      dispatch(resetDrafts())
      dispatch(setDraftTagsJob([]))
      dispatch(
        updateModalProps({
          isAdd: false,
          isEdit: true,
          status: "edit",
          is_communication_edit: true,
          type: FeaturesTypes.LEAD,
        })
      )
      setIsChanged(false)
      setIsLoading(false)
    }
  }, [currentTags, draftTodos, communications, specificLead, data])

  const handleUploadResume = async (file) => {
    const title = fileTitle.trim() ? fileTitle : file.name
    const res = await onUpload(file, title)
    if (!res?.data) {
      setFileTitle("")
      setOpenResume(false)
      return
    }
    if (!specificLead?.id) {
      await handleAutoCreateLead(res.data)
      return
    }
    if (specificLead?.id) {
      await updateJobAction({ resume_id: res.data.id, id: specificLead.id })
    }
    setFileTitle("")
    setOpenResume(false)
  }

  const handleAutoCreateWithFileLead = async (resume) => {
    if (!specificLead?.id) {
      await handleAutoCreateLead(resume)
      return
    }
  }

  useEffect(() => {
    if (specificLead !== null && isEdit && specificLead?.id) {
      dispatch(getCommunications(`?job_id=${specificLead?.id}`))
      dispatch(getJobTodos(`?job_id=${specificLead?.id}`))
    }
  }, [])
  useEffect(() => {
    dispatch(resetDrafts())
    dispatch(resetDraftTodoList())
    if (!isCompanyExist() && data?.company?.id) {
      dispatch(getCompany(data?.company?.id))
    }
    if (!isContactExist() && data?.contact?.id) {
      dispatch(getContact(data?.contact?.id))
    }

    return () => {
      dispatch(resetDrafts())
      dispatch(setDraftTagsJob([]))
    }
  }, [])
  useEffect(() => {
    if (!isEqual(data.company, specificLead.company)) {
      if (specificLead.company) {
        setData((prev) => {
          return {
            ...prev,
            company: specificLead.company,
          }
        })
      }
    }
    //eslint-disable-next-line
  }, [specificLead.company])

  useEffect(() => {
    if (!isEqual(data.contact, specificLead.contact)) {
      setData((prev) => {
        return {
          ...prev,
          contact: specificLead.contact,
        }
      })
    }
  }, [specificLead.contact])

  const handleDownload = useCallback(
    (file) => {
      dispatch(downloadFile(file))
    },
    [dispatch]
  )
  const renderResumeTitle = useCallback(() => {
    const link = (file, name) => {
      if (file) {
        return <LinkItem caption={name} callback={handleDownload} file={file} />
      }
    }
    if (!isEmpty(specificLead)) {
      return specificLead?.resume?.title?.lastIndexOf(".") > 0
        ? link(
            specificLead.resume,
            removeExtension(specificLead?.resume?.title)
          )
        : link(specificLead.resume, specificLead?.resume?.title)
    } else {
      return removeExtension(data?.resume?.title)
    }
  }, [data?.resume?.title, specificLead, handleDownload])

  const hide = () => {
    handleVisibleChange(false)
  }

  const unhide = () => {
    if (data.url) setUrlIsEdit(false)
    handleVisibleChange((prev) => !prev)
  }
  const handleOutsideClick = (e) => {
    if (
      e.target.innerText === "Cancel" ||
      e.target.innerText === "Clear link ?"
    )
      return
    hide()
  }
  const handleOpenCompanyEdit = () => {
    if (companyIsEmpty()) {
      return
    }
    const myCompany = isCompanyExist()
    const isClickable = isClickOnCompany()
    if (myCompany && isClickable) {
      handleClick(myCompany, FeaturesTypes.COMPANY, myCompany)
    }
  }

  const handleOpenContactEdit = () => {
    if (contactIsEmpty()) {
      return
    }
    const myContact = isContactExist()
    const isClickable = isClickOnContact()
    if (myContact && isClickable) {
      handleClick(myContact, FeaturesTypes.CONTACT, myContact)
    }
  }

  const handleDeleteOrDiscard = async (confirm) => {
    if (isEmpty(specificLead)) {
      return
    }
    if (confirm) {
      await deleteJobAction(specificLead.id)
    } else {
      await updateJobAction({ status_id: JOB_DISCARDED, id: specificLead.id })
    }
    onClose()
  }
  const handleClickOnLinkedin = () => {
    unhide()
  }
  const closeResume = () => {
    setOpenResume(false)
  }
  const handleOpenResumeModal = () => {
    if (companyIsEmpty() && contactIsEmpty()) {
      setDefaultActiveKey("1")
      setCanShow(true)
      setTimeout(() => companyNameRef?.current?.focus(), 100)
      return
    }
    setOpenResume(true)
  }
  const companyIsEmpty = useCallback(() => {
    return isEmpty(data.company) || !data?.company?.name.trim()
  }, [data.company])

  const contactIsEmpty = useCallback(() => {
    return isEmpty(data.contact) || !data?.contact?.first_name.trim()
  }, [data.contact])

  const resetResume = () => {
    dispatch(updateJobTH({ resume_id: "", id: specificLead.id }))
  }
  const todoIsDone = useCallback(() => {
    return (
      specificLead?.todos &&
      0 in specificLead.todos &&
      specificLead.todos[0].done_dt
    )
  }, [specificLead.todos])

  const handleSaveTodo = async (value) => {
    dispatch(updateDraftTodoList(value))
    if (companyIsEmpty() && contactIsEmpty()) {
      setDefaultActiveKey("1")
      setCanShow(true)
      setTimeout(() => companyNameRef?.current?.focus(), 100)
      return
    } else {
      if (isAdd) await callbackAutoCreateLead()
    }
  }
  const renderBadge = useCallback(() => {
    const count = todoIsDone() ? "Done" : "Due"
    return (
      <Badge
        style={{
          backgroundColor: "#009EEF",
          height: "20px",
          fontSize: "12px",
          marginTop: "2px",
          marginLeft: "15px",
        }}
        count={count}
      />
    )
  }, [todoIsDone])

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "day").endOf("day")
  }
  const closePicker = (e) => {
    e.stopPropagation()
    if ((e.key === "Escape" || e?.type === "blur") && pickerIsOpen) {
      setPickerIsOpen(false)
      focusOnLastModal()
    }
  }

  const onOpenPicker = () => {
    setPickerIsOpen(true)
  }
  const onChangeDatePicker = (date) => {
    updateTodoAction(
      specificLead.todos[0].id,
      { due_dt: date, status_id: 1, done_dt: null },
      specificLead.id
    )
      .then(() => setPickerIsOpen(false))
      .then(() => getTodosIfNeed())
  }
  const onTodoCheckBoxChange = () => {
    const status_id = specificLead.todos[0].status_id === 1 ? 2 : 1
    const done_dt = status_id === 2 ? moment().toISOString() : null
    updateTodoAction(
      specificLead.todos[0].id,
      { status_id, done_dt },
      specificLead.id
    ).then(getTodosIfNeed)
  }
  const handleEnterBlurOwnTodo = async () => {
    if (ownTodoDesc === specificLeadOwnTodo.description)
      return setIsEditTodo(false)
    ownTodoDesc &&
      (await updateTodoAction(
        specificLeadOwnTodo.id,
        { description: ownTodoDesc },
        specificLead.id
      ))
    setIsEditTodo(false)
  }

  const onBlurCompanyInput = useCallback(() => {
    setCompanyIsEdit(false)
    setSearchCompanyList([])
  })

  const onBlurContactInput = useCallback(() => {
    setContactEdit(false)
    setSearchContactList([])
  })

  useEffect(() => {
    !data.company?.name &&
      !data?.contact?.first_name &&
      companyNameRef?.current?.focus()
  }, [])

  return {
    urlIsEdit,
    setUrlIsEdit,
    handleAddLead,
    handleCloseOrConfirm,
    data,
    handleEditLead,
    linkInputEl,
    hide,
    popOverVisible,
    unhide,
    handleClickOnLinkedin,
    specificLead,
    addTagStep,
    inputRef,
    setAddTagStep,
    companyIsEmpty,
    canShow,
    handleSearch: debouncedAction,
    setCanShow,
    options: companiesResults,
    handleOpenResumeModal,
    setData,
    handleJobApply,
    checkFollow,
    renderBadge,
    todoIsDone,
    handleOpenCompanyEdit,
    confirmModal,
    setConfirmModal,
    handleUploadResume,
    toggleCommunicationModal,
    renderResumeTitle,
    resetResume,
    renderDueDate,
    handleDeleteOrDiscard,
    todos,
    draftTodos,
    communications,
    openResume,
    fileTitle,
    setFileTitle,
    closeResume,
    leadModalRef,
    companyNameRef,
    handleSaveTodo,
    handleOutsideClick,
    setCompanyIsEdit,
    companyIsEdit,
    isCompanyExist,
    isClickable,
    datePickerRef,
    pickerIsOpen,
    disabledDate,
    closePicker,
    onOpenPicker,
    onChangeDatePicker,
    onTodoCheckBoxChange,
    isEditTodo,
    setIsEditTodo,
    ownTodoDesc,
    setOwnTodoDesc,
    specificLeadOwnTodo,
    updateTodoAction,
    handleEnterBlurOwnTodo,
    onHoverDate,
    mouseHover,
    isLoading,
    handleAutoCreateWithFileLead,
    leadConfig: getNameLead(subType),
    CRM_MODE,
    subType,
    setPickerIsOpen,
    isSearchCompanyLoading,
    isContactEdit,
    setContactEdit,
    contactNameRef,
    debouncedContactSearch,
    contactsResults,
    contactIsEmpty,
    isClickableContact,
    handleOpenContactEdit,
    isSearchContactLoading,
    onBlurCompanyInput,
    onBlurContactInput,
    setSearchContactList,
    setSearchCompanyList,
  }
}
