import React, { useMemo } from "react"
import { Avatar, Dropdown, Layout, Menu, Space } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { notifyServerWhenLogout } from "../store/actions/user"
import { useDispatch, useSelector } from "react-redux"
import { getUserData, getIsActionBill, getUserSubscriptionTypeSelector } from "../store/selectors/user/getUserData"
import { URLS } from "../common/urls"
import { getNameLead } from "../common/helpers"
import Logo from "../assets/images/logo.svg"
import { Link, useLocation, useHistory } from "react-router-dom"
import { Search } from "./Search"
import { getUserIsAuthenticated } from "../store/selectors/user/get-login-status"
import { redirectExclusionUrls } from "../common/constants"

const { Header } = Layout
export const AuthHeader = () => {
  const profile = useSelector(getUserData)
  const isActionBill = useSelector(getIsActionBill)
  const isAuth = useSelector(getUserIsAuthenticated)
  const subType = useSelector(getUserSubscriptionTypeSelector)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const onLogout = () => {
    dispatch(notifyServerWhenLogout())
    history.push(URLS.LOGIN)
  }
  const currentLocation = location.pathname
  const hideHeader = useMemo(
    () => redirectExclusionUrls.some((item) => item.includes(currentLocation)),
    [currentLocation]
  )
  if (!isAuth || hideHeader) {
    return <></>
  }

  const items = [
    {
      label: (
        <Link
          style={{
            color: "rgba(0, 0, 0, 0.85)",
          }}
          to={URLS.VERIFY}
        >
          My Profile Setting...
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link
          style={{
            color: "rgba(0, 0, 0, 0.85)",
          }}
          to={URLS.HELP}
        >
          Help
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <div
          onClick={onLogout}
        >
          Logout
        </div>
      ),
      key: "2",
    },
  ]

  const menuItems = [
    {
      label: <Link to={URLS.HOME}>Dashboard</Link>,
      key: '1',
      className: 'first-menu-item'
    },
    {
      label: <Link to={URLS.LEADS}>{getNameLead(subType).name}</Link>,
      key: '2',
      className: "two-menu-item"
    },
    {
      label: <Link to={URLS.CONTACTS}>Contact</Link>,
      key: "3",
      className: "three-menu-item"
    },
    {
      label: <Link to={URLS.COMPANY}>Company</Link>,
      key: "4",
      className: "four-menu-item"
    },
    {
      label: <Link to={`${URLS.LIBRARY}/0`}>Notes</Link>,
      key: "5",
      className: "five-menu-item"
    }
  ];


  return (
    <Header className="header">
      <div style={isActionBill ? { pointerEvents: "none" } : {}} className="logo">
        <Link to={URLS.HOME}>
          <img src={Logo} alt="" />
        </Link>
      </div>
      <Menu
        className="header-menu"
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={
          currentLocation === URLS.HOME
            ? "1"
            : currentLocation === URLS.LEADS
              ? "2"
              : currentLocation === URLS.CONTACTS
                ? "3"
                : currentLocation === URLS.COMPANY
                  ? "4"
                  : currentLocation.includes(URLS.LIBRARY)
                    ? "5"
                    : "null"
        }
        selectedKeys={
          currentLocation === URLS.HOME
            ? "1"
            : currentLocation === URLS.LEADS
              ? "2"
              : currentLocation === URLS.CONTACTS
                ? "3"
                : currentLocation === URLS.COMPANY
                  ? "4"
                  : currentLocation.includes(URLS.LIBRARY)
                    ? "5"
                    : "null"
        }
        style={{
          flex: "auto",
          background: "transparent",
          ...(isActionBill ? { pointerEvents: "none" } : {})
        }}
        items={menuItems}
      />

      <div style={isActionBill ? { pointerEvents: "none" } : {}} className="d-flex header-search-btn">
        <Search />
      </div>


      <Dropdown trigger={["click"]} menu={{ items }}>
        <Space>
          <div className="ant-dropdown-trigger ant-dropdown-link cursor-pointer">
            <span className="profile-username">
              {profile?.first_name || profile?.username}
            </span>
            <Avatar
              size={40}
              icon={<UserOutlined />}
              style={{
                backgroundColor: "#009EEF",
              }}
              gap={4}
            />
          </div>
        </Space>
      </Dropdown>
    </Header>
  )
}
