import {
  SET_JOBS_DATA,
  APPLY_JOB,
  UPDATE_JOB_DATA_SUCCESS,
  CLEAR_SAVE_STATUS,
  DISCARD_JOB,
  REVERT_JOB,
  DELETE_JOB,
  ADD_JOB,
  REMOVE_TODO_FROM_LEAD,
  SET_SPECIFIC_LEAD,
  UPDATE_SPECIFIC_JOB,
  SET_JOB_TODOS,
  SET_NEW_JOB_TODO,
  UPDATE_JOB_FILTERS,
  SET_JOB_PENDING_STATUS,
  SET_JOB_TO_LIST,
  SET_JOB_FETCHING,
  CLEAR_JOB_FETCHING,
  SET_DRAFT_TAGS_JOB,
} from "../../action-types/jobs"
import { isEmpty, uniqBy, sortBy } from "lodash"
import { EDIT_TAG_SUCCESS, REMOVE_TAG_SUCCESS } from "../../action-types/tags"
import {
  DRAG_TODO_IN_SPECIFIC_CONTAINER,
  REMOVE_TODO,
  UPDATE_TODO,
} from "../../action-types/todos"
import { JOB_NOT_APPLIED } from "../../../common/constants"
import {
  removeTagFromTables,
  getArrayUniqueValue,
} from "../../../common/helpers"
import {
  EDIT_CONTACT_SUCCESS,
  DELETE_CONTACT,
} from "../../action-types/contacts"
import { RESET_APPLICATION_DATA } from "../../action-types/user"
import { SAVE_EDITED_COMPANY } from "../../action-types/companies"

const initState = {
  jobs: [],
  pending: true,
  saved: false,
  discarded: null,
  updated: false,
  specificLead: {},
  todos: [],
  draftTags: [],
  filterBy: JOB_NOT_APPLIED,
  isPending: true,
  isPendingAppliedJobs: false,
  fetchingJobs: new Set(),
}
export const jobsReducer = (state = initState, action) => {
  switch (action.type) {
    case DRAG_TODO_IN_SPECIFIC_CONTAINER: {
      const isJobTodos = state.todos.every(
        (item) => item.job === Number(action.container)
      )
      if (isJobTodos) {
        return {
          ...state,
          todos: action.payload,
        }
      }
      return {
        ...state,
      }
    }
    case SET_JOB_PENDING_STATUS: {
      return {
        ...state,
        isPending: action.payload,
      }
    }
    case UPDATE_JOB_FILTERS: {
      return {
        ...state,
        filterBy: action.payload,
      }
    }
    case UPDATE_TODO: {
      const todos = isEmpty(state.todos)
        ? []
        : state.todos.map((item) => {
            return item.id === action.payload.id ? action.payload : item
          })
      const specificLeadTodos = state.specificLead?.todos?.map((item) => {
        return item.id === action?.payload?.id ? action?.payload : item
      })
      const jobs = state.jobs.map((job) => {
        return job?.id === action?.payload?.job
          ? {
              ...job,
              todos: job?.todos?.map((item) => {
                return item?.id === action?.payload?.id ? action?.payload : item
              }),
            }
          : job
      })
      return {
        ...state,
        jobs,
        todos,
        specificLead: {
          ...state.specificLead,
          todos: specificLeadTodos,
        },
      }
    }
    case REMOVE_TODO: {
      const todos = isEmpty(state.todos)
        ? []
        : state.todos.filter((item) => {
            return item.id !== action.payload
          })
      return {
        ...state,
        todos,
        specificLead: {
          ...state.specificLead,
          todos: [],
        },
      }
    }
    case SET_NEW_JOB_TODO: {
      return {
        ...state,
        todos: [action.payload].concat(state.todos),
        jobs: state.jobs.map((job) => {
          return job.id === action.payload.job
            ? {
                ...job,
                todos: [action.payload],
              }
            : job
        }),
      }
    }
    case SET_JOB_TODOS: {
      const todos = sortBy(action.payload, "drag_index")
      return {
        ...state,
        todos,
      }
    }
    case SET_DRAFT_TAGS_JOB: {
      return {
        ...state,
        draftTags: action.payload,
      }
    }
    case REMOVE_TAG_SUCCESS: {
      let newState = {
        ...state,
        draftTags: state.draftTags?.filter(
          (item) => item?.tag !== action.removedTag.tag
        ),
        jobs: state.jobs.map((job) => {
          return {
            ...job,
            tags: removeTagFromTables(job.tags, action.removedTag.tag),
          }
        }),
      }
      if (!isEmpty(state.specificLead)) {
        newState.specificLead = {
          ...state.specificLead,
          tags: state.specificLead?.tags?.filter((item) => {
            return item.tag !== action.removedTag.tag
          }),
        }
      }
      return newState
    }

    case EDIT_TAG_SUCCESS: {
      const jobs = state.jobs?.map((item) => {
        return {
          ...item,
          tags: item?.tags?.map((item) => {
            return item.tag?.trim() === action.prevTag.tag?.trim()
              ? action.payload
              : item
          }),
        }
      })
      if (isEmpty(state.specificLead)) {
        return {
          ...state,
          jobs,
          draftTags: state.draftTags?.map((item) => {
            return item.tag?.trim() === action.prevTag.tag?.trim()
              ? action.payload
              : item
          }),
        }
      }
      return {
        ...state,
        jobs,
        specificLead: {
          ...state.specificLead,
          tags: state.specificLead?.tags?.map((item) => {
            return item.tag?.trim() === action.prevTag.tag?.trim()
              ? action.payload
              : item
          }),
        },
      }
    }
    case RESET_APPLICATION_DATA: {
      return {
        ...state,
        jobs: [],
        pending: true,
        saved: false,
        discarded: null,
        updated: false,
        specificLead: {},
        todos: [],
        filterBy: JOB_NOT_APPLIED,
        isPending: false,
        draftTags: [],
      }
    }
    case SET_JOB_TO_LIST: {
      if (state.jobs.some((item) => item.id === action.payload.id)) {
        return {
          ...state,
        }
      }
      return {
        ...state,
        jobs: [...state.jobs, action.payload],
      }
    }
    case SAVE_EDITED_COMPANY: {
      return {
        ...state,
        jobs: state.jobs.map((item) => {
          if (item?.company?.id === action.payload.id) {
            return {
              ...item,
              company: action.payload,
            }
          } else {
            return item
          }
        }),
      }
    }
    case SET_JOB_FETCHING:
      return {
        ...state,
        fetchingJobs: new Set(state.fetchingJobs).add(action.payload),
      }
    case CLEAR_JOB_FETCHING:
      return {
        ...state,
        fetchingJobs: new Set(),
      }
    case SET_JOBS_DATA: {
      const updatedItems = getArrayUniqueValue(state.jobs, action.payload)
      return {
        ...state,
        pending: false,
        isPending: false,
        jobs: updatedItems,
      }
    }
    case SET_SPECIFIC_LEAD: {
      return {
        ...state,
        specificLead: action.payload,
      }
    }
    case APPLY_JOB: {
      return {
        ...state,
        pending: true,
        jobs: state.jobs.map((job) => {
          return job.id === action.payload.id
            ? {
                ...job,
                status_id: action.payload.status_id,
              }
            : job
        }),
      }
    }
    case UPDATE_SPECIFIC_JOB: {
      const jobs = state.jobs.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })
      return {
        ...state,
        jobs,
        specificLead: action.payload,
      }
    }
    case REMOVE_TODO_FROM_LEAD: {
      const data = state.specificLead
      data.todos = []
      return {
        ...state,
        specificLead: data,
      }
    }
    case UPDATE_JOB_DATA_SUCCESS: {
      let items = state.jobs
      const index = items.findIndex((item) => item.id === action.payload.id)
      if (index !== -1) {
        items.splice(index, 1, action.payload)
      } else {
        items = items.concat([action.payload])
      }
      return {
        ...state,
        pending: false,
        saved: true,
        updated: true,
        jobs: items,
      }
    }
    case CLEAR_SAVE_STATUS: {
      return {
        ...state,
        pending: false,
        saved: false,
      }
    }
    case DELETE_JOB: {
      return {
        ...state,
        jobs: state.jobs.filter((item) => {
          return item.id !== action.payload
        }),
      }
    }
    case DISCARD_JOB: {
      const jobs = state.jobs.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            status_id: 3,
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        jobs,
      }
    }
    case REVERT_JOB: {
      return {
        ...state,
        discarded: null,
      }
    }
    case ADD_JOB: {
      const jobs = [action.payload, ...state.jobs]
      return {
        ...state,
        jobs: uniqBy(jobs, "id"),
      }
    }
    case DELETE_CONTACT: {
      return {
        ...state,
        specificLead: {
          ...state.specificLead,
          contact:
            state.specificLead?.contact?.id === action.payload
              ? null
              : state.specificLead?.contact,
        },
        jobs: state.jobs.map((job) => {
          return {
            ...job,
            contact: job?.contact?.id === action.payload ? null : job?.contact,
          }
        }),
      }
    }

    case EDIT_CONTACT_SUCCESS: {
      return {
        ...state,
        specificLead: {
          ...state.specificLead,
          contact:
            state.specificLead?.contact?.id === action?.payload?.id
              ? action.payload
              : state.specificLead?.contact,
        },
        jobs: state.jobs.map((job) => {
          return {
            ...job,
            contact:
              job?.contact?.id === action?.payload?.id
                ? action.payload
                : job.contact,
          }
        }),
      }
    }
    default: {
      return {
        ...state,
        pending: false,
      }
    }
  }
}
